// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-administrator-js": () => import("./../../../src/pages/administrator.js" /* webpackChunkName: "component---src-pages-administrator-js" */),
  "component---src-pages-blog-curse-of-the-nagas-js": () => import("./../../../src/pages/blog/curse-of-the-nagas.js" /* webpackChunkName: "component---src-pages-blog-curse-of-the-nagas-js" */),
  "component---src-pages-blog-curse-of-the-nagas-malayalam-js": () => import("./../../../src/pages/blog/curse-of-the-nagas-malayalam.js" /* webpackChunkName: "component---src-pages-blog-curse-of-the-nagas-malayalam-js" */),
  "component---src-pages-blog-gems-js": () => import("./../../../src/pages/blog/gems.js" /* webpackChunkName: "component---src-pages-blog-gems-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-rudrakshas-js": () => import("./../../../src/pages/blog/rudrakshas.js" /* webpackChunkName: "component---src-pages-blog-rudrakshas-js" */),
  "component---src-pages-blog-yanthras-js": () => import("./../../../src/pages/blog/yanthras.js" /* webpackChunkName: "component---src-pages-blog-yanthras-js" */),
  "component---src-pages-daivajnas-js": () => import("./../../../src/pages/daivajnas.js" /* webpackChunkName: "component---src-pages-daivajnas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-electional-astrology-js": () => import("./../../../src/pages/services/electional-astrology.js" /* webpackChunkName: "component---src-pages-services-electional-astrology-js" */),
  "component---src-pages-services-horary-prediction-js": () => import("./../../../src/pages/services/horary-prediction.js" /* webpackChunkName: "component---src-pages-services-horary-prediction-js" */),
  "component---src-pages-services-horoscope-matching-js": () => import("./../../../src/pages/services/horoscope-matching.js" /* webpackChunkName: "component---src-pages-services-horoscope-matching-js" */),
  "component---src-pages-services-horoscope-prediction-js": () => import("./../../../src/pages/services/horoscope-prediction.js" /* webpackChunkName: "component---src-pages-services-horoscope-prediction-js" */),
  "component---src-pages-services-horoscope-writing-js": () => import("./../../../src/pages/services/horoscope-writing.js" /* webpackChunkName: "component---src-pages-services-horoscope-writing-js" */)
}

